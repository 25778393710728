import React from 'react';

import { AuthUserContext, withAuthorization } from '../../modules/Session';
import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';

const AccountPage = () => (
    <AuthUserContext.Consumer>
        { authUser => (
            <div>
                <h1>Account</h1>
                <h3>Hello, {authUser.displayName}</h3>
                <p>Password options</p>
                <PasswordForgetForm />
                <PasswordChangeForm />
            </div>
        )}
    </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);
