import React from 'react';

import { withFirebase } from '../../modules/Firebase';

const SignOutButton = ({ firebaseService }) => (
    <button type="button" onClick={ firebaseService.doSignOut }>
        Sign Out
    </button>
);

export default withFirebase(SignOutButton);
