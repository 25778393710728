import React, { Component } from 'react';

import { withFirebase } from '../../modules/Firebase';
import loadingIcon from '../../images/loading.gif';
import causebotConfig from '../../config/causebotConfig';

const INITIAL_STATE = {
    orgName: '',
    orgDescription: '',
    orgEin: '',
    orgUrl: '',
    loading: false,
    submitted: false,
    error: null,
};

class OrgRequestForm extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = async event => {
        event.preventDefault();

        this.setState({ loading: true });

        const { orgName, orgDescription, orgEin, orgUrl } = this.state,
              { requesterDisplayName, requesterId, requesterEmail } = this.props;

        const res = await fetch(
            causebotConfig.orgRequestUrl,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-admin-token': causebotConfig.accessToken
                },
                body: JSON.stringify({
                    requesterId,
                    requesterEmail,
                    requesterDisplayName,
                    orgName,
                    orgDescription,
                    orgEin,
                    orgUrl
                })
            })
            .catch(err => console.error('ERROR:', err));

        if (!res) { return; }
        if (!res.ok) {
            console.error('Failed request:', res.statusCode);
            // TODO: Handle error

            return;
        }

        this.setState({ loading: false, submitted: true });
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { orgName, orgDescription, orgEin, orgUrl, loading, submitted, error } = this.state;

        const isInvalid = orgName === '' || orgDescription === '' || orgEin === '' || orgUrl === '';

        return (<>
            {submitted
            ? (
                <div>
                    <p>Thanks, we've recieved your submission! We'll get back to you via email once we've reviewed your organization's details. If you'd like to subit another organization for review, you can do so below. Come back to this page any time to check the status of your requests.</p>
                    <form>
                        <button onClick={() => this.setState({ submitted: false })}>
                            Submit Another
                        </button>
                    </form>
                </div>
            ) : (
                <div>
                    <p>Fill out the form below to request that your organization be added to the ScoreSide platform. You can see the status of any previous requests in the list at the bottom.</p>

                    <form onSubmit={this.onSubmit}>
                        <input
                            className="input"
                            name="orgName"
                            value={orgName}
                            onChange={this.onChange}
                            type="text"
                            placeholder="Organization Name"
                            disabled={loading}
                        />
                        <textarea
                            className="input"
                            rows="4"
                            name="orgDescription"
                            value={orgDescription}
                            onChange={this.onChange}
                            type="text"
                            placeholder="Organization Description"
                            disabled={loading}
                        />
                        <input
                            className="input"
                            name="orgEin"
                            value={orgEin}
                            onChange={this.onChange}
                            type="text"
                            placeholder="Organization EIN"
                            disabled={loading}
                        />
                        <input
                            className="input"
                            name="orgUrl"
                            value={orgUrl}
                            onChange={this.onChange}
                            type="text"
                            placeholder="Organization Website URL"
                            disabled={loading}
                        />
                        <button className="loading-state" disabled={isInvalid || loading} type="submit">
                            <span className={loading ? "loading-icon" : "loading-icon hidden"}><img src={loadingIcon} alt="loading..." /></span><span className={loading ? "label hidden" : "label"}>Submit</span>
                        </button>

                        {error && <p>{error.message}</p>}
                    </form>
                </div>
            )}
        </>);
    }
}

export default withFirebase(OrgRequestForm);
