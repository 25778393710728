const causebotConfig = {
    get prod() {
        return {
            baseUrl: 'https://cloud.unapologetic.io/causebot',
            accessToken: '])8g9(#YUv9&Xu4+6T/9}7#qhw964('
        };
    },

    get staging() {
        return {};
    },

    get dev() {
        return {
            baseUrl: 'https://cloud.unapologetic.io/causebot-dev',
            accessToken: '])8g9(#YUv9&Xu4+6T/9}7#qhw964('
        };
    },

    get local() {
        return {
            baseUrl: 'https://causebot-local.ngrok.io',
            accessToken: '])8g9(#YUv9&Xu4+6T/9}7#qhw964('
        };
    },

    get local_prod() {
        return {
            baseUrl: 'https://causebot-local.ngrok.io',
            accessToken: '])8g9(#YUv9&Xu4+6T/9}7#qhw964('
        };
    },

    get test() {
        return {
            baseUrl: 'https://example.com/causebot',
            accessToken: 'test'
        };
    },

    get config() {
        return {
            orgRequestUrl: `${this[process.env.REACT_APP_ENV].baseUrl}/admin/org-request`,

            adminRequestUrl: `${this[process.env.REACT_APP_ENV].baseUrl}/admin/admin-request`,

            ...this[process.env.REACT_APP_ENV]
        };
    }
};

export default causebotConfig.config;
