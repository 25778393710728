import React from 'react';
import { compose } from 'recompose';

import { AuthUserContext, roleService, withAuthorization } from '../../modules/Session';
import { withFirebase } from '../../modules/Firebase';
import OrganizationList from './OrganizationList';
import CreateAdminForm from './CreateAdminForm';

const AdminPage = () => (
    <div>
        <AuthUserContext.Consumer>
            { authUser => (
                <div>
                    {roleService.isCharityAdmin(authUser) &&
                    <div>
                        <h1>Organization Administration</h1>

                        <h3>Display Name</h3>
                        <p>{authUser.displayName}</p>

                        <h3>Email</h3>
                        <p>{authUser.email}</p>

                        <OrganizationList orgRefs={authUser.admin.organizations} email={authUser.email} />
                    </div>
                    }
                    {roleService.isSuperuser(authUser) &&
                    <div>
                        <h1>Superuser Administration</h1>

                        <h3>Set User as Organization Admin</h3>
                        <CreateAdminForm />
                    </div>
                    }
                </div>
            )}
        </AuthUserContext.Consumer>
    </div>
);

const condition = authUser => roleService.isCharityAdmin(authUser) || roleService.isSuperuser(authUser);

export default compose(
    withAuthorization(condition),
    withFirebase
)(AdminPage)
