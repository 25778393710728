import React, { Component, useState } from 'react';
import { compose } from 'recompose';
import { format } from 'date-fns';
import { withFirebase } from '../../modules/Firebase';
import loadingIcon from '../../images/loading.gif';
import causebotConfig from '../../config/causebotConfig';

class OrgRequestListBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            orgRequests: []
        };
    }

    sortRequests(a, b) {
        const aVal = a.accepted == null ? 2 : (a.accepted ? 1 : 0);
        const bVal = b.accepted == null ? 2 : (b.accepted ? 1 : 0);

        if (bVal !== aVal) {
            return bVal - aVal;
        } else {
            return b.dateCreated.toMillis() - a.dateCreated.toMillis();
        }
    }

    componentDidMount = async() => {
        this.setState({ loading: true });
        
        const orgRequestListSnapshot = await this.props.firebaseService.getSubcollection(
            'users',
            this.props.userId,
            'orgRequests'
        ).catch(err => console.error('ERROR **', err));

        if (orgRequestListSnapshot) {
            this.setState({
                loading: false,
                orgRequests: orgRequestListSnapshot.docs
                    .map(orgRequestDoc => orgRequestDoc.data())
                    .filter(orgRequest => !orgRequest.hidden)
                    .sort((a, b) => this.sortRequests(a, b))
            });
        } else {
            this.setState({
                loading: false,
                orgRequests: []
            });
        }
    }

    render() {
        const { loading, orgRequests } = this.state;
        const { filter } = this.props;

        return (
            <div>
                { (orgRequests && orgRequests.length > 0) && <h3>Your Organization Requests</h3> }
                            
                { loading && <div>Loading...</div> }

                <ul className="org-request-list">
                    {orgRequests
                        .filter(orgRequest => {
                            if (filter === 'pending') {
                                return orgRequest.accepted == null;
                            } else {
                                return orgRequest.accepted != null;
                            }
                        })
                        .map(orgRequest => (
                        <li className="org-request" key={orgRequest.id}>
                            <OrgRequest data={orgRequest} />
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

const DeleteConfirmationModal = ({ id, userId, cancelFunction }) => {
    const [ loading, setLoading ] = useState(false);
    const [ deleted, setDeleted ] = useState(false);

    const confirm = async (e) => {
        e.preventDefault();
        setLoading(true);

        const res = await fetch(
            causebotConfig.orgRequestUrl,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-admin-token': causebotConfig.accessToken
                },
                body: JSON.stringify({ id, userId })
            })
            .catch(err => console.error('ERROR:', err));

        if (!res) { return; }
        if (!res.ok) {
            console.error('Failed request:', res.status);
            return;
        }

        setDeleted(true);
        setLoading(false);
    }

    const cancel = (e) => {
        e.preventDefault();

        cancelFunction();
    }

    return (
        <span className="modal">
            { deleted ? (
                 <label>This request has been deleted.</label>
            ) : (
                <form>
                    <label>Are you sure you want to delete this request?</label>
                    <button className="loading-state" disabled={loading} onClick={(e) => confirm(e)}>
                        <span className={loading ? "loading-icon" : "loading-icon hidden"}><img src={loadingIcon} alt="loading..." /></span><span className={loading ? "label hidden" : "label"}>Delete</span>
                    </button>
                    <button onClick={(e) => cancel(e)}>
                        Cancel
                    </button>
                </form>
            )}
        </span>
    );
}

const OrgRequest = ({ data }) => {
    const [ loadingEmail, setLoadingEmail ] = useState(false);
    const [ deleteConfirmationModal, setDeleteConfirmationModal ] = useState(false);

    const openEmail = (e) => {
        e.preventDefault();
        setLoadingEmail(true);

        setTimeout(() => { setLoadingEmail(false); }, 3000);

        const address = 'info@scoreside.io',
              subject = 'ScoreSide Organization Request Follow Up',
              body = `User ID: ${data.requesterId}\n` +
                      `Org Request ID: ${data.id}\n` +
                      `Org request name: ${data.orgName}\n\n\n`;

        window.open(`mailto:${address}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`, '_self');
    }

    const deleteRequestModal = (e) => {
        e.preventDefault();

        setDeleteConfirmationModal(true);
    }

    const closeConfirmationModal = () => {
        setDeleteConfirmationModal(false);
    }

    const requestAdmin = e => {
        e.preventDefault();

        window.open(`${window.location.origin}/admin-request/${data.id}`, '_self');
    }

    return (
        <>
        { deleteConfirmationModal && <DeleteConfirmationModal id={data.id} userId={data.requesterId} cancelFunction={closeConfirmationModal} /> }
        <div className={deleteConfirmationModal ? 'hidden' : ''}>
            <div>
                {data.orgName}
            </div>
            <div>
                Status: {data.accepted != null
                    ? (data.accepted ? 'Accepted' : 'Rejected')
                    : 'Pending'}
            </div>
            <div>
                Submitted: {format(data.dateCreated.toDate(), 'MMMM d, yyyy')}
            </div>
            { data.adminRequest &&
                <div>
                    Admin Status: {data.adminRequest.accepted != null
                        ? (data.adminRequest.accepted ? 'You are an admin for this org' : 'Your admin request was rejected')
                        : 'Pending'}
                </div>
            }
            <form>
                <button className="loading-state" disabled={loadingEmail} onClick={(e) => openEmail(e)}>
                    <span className={loadingEmail ? "loading-icon" : "loading-icon hidden"}><img src={loadingIcon} alt="loading..." /></span><span className={loadingEmail ? "label hidden" : "label"}>Request Info</span>
                </button>

                { (data.accepted && !data.adminRequest) &&
                    <button onClick={requestAdmin}>
                        Request Admin
                    </button>
                }

                <button onClick={deleteRequestModal}>
                    Delete
                </button>
            </form>
        </div>
        </>
    );
};

const OrgRequestList = compose(
    withFirebase
)(OrgRequestListBase);

export default OrgRequestList
