import React, { Component } from 'react';

import stripeConfig from '../../config/stripeConfig';

class OrganizationList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            orgs: []
        };
    }

    componentDidMount = async() => {
        this.setState({ loading: true });
        
        const orgList = await Promise.all(
            this.props.orgRefs.map(async(orgRef) => {
                const org = await orgRef.get();

                return { ...org.data(), uid: org.id };
            })
        );

        this.setState({
            loading: false,
            orgs: orgList
        });
    }

    render() {
        const { loading, orgs } = this.state;

        return (
            <div>
                <h3>Organizations</h3>
                            
                {loading && <div>Loading...</div>}

                <ul className="org-list">
                    {orgs.map(org => (
                        <li key={org.uid}>
                            <Organization org={org} email={this.props.email} />
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

const Organization = ({org, email}) => (
    <div className="org">
        <img src={org.logoUrl} alt={`${org.name} Logo`}/>
        <div className="org-name">{org.name}</div>
        <StripeConnectLink org={org} email={email} />
    </div>
);

const StripeConnectLink = ({org, email}) => (
    org.stripeConnect ? (
        <div className="stripe-status">Connected to Stripe</div>
    ) : (
        <div className="stripe-status no-padding"><a href={buildStripeOAuthLink(org, email)}>Connect to Stripe</a></div>
    )
);

function buildStripeOAuthLink(org, email) {
    const state = org.uid,
          redirectURI = `${window.location.origin}/oauth/stripe`,
          stripe_suggestions = `stripe_user[business_type]=company&stripe_user[email]=${encodeURIComponent(email)}`;

    return `${stripeConfig.oauthUrl}?redirect_uri=${redirectURI}&client_id=${stripeConfig.clientId}&state=${state}&${stripe_suggestions}`;
}

export default OrganizationList
