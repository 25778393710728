import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import logo from './images/logo.png';

import HomePage from './components/Home';
import Navigation from './components/Navigation';
import SignUpPage from './components/SignUp';
import SignInPage from './components/SignIn';
import PasswordForgetPage from './components/PasswordForget';
import AccountPage from './components/Account';
import AdminPage from './components/Admin';
import OAuthPage from './components/OAuth';
import OrgRequestPage from './components/OrgRequestPage';
import AdminRequestPage from './components/AdminRequestPage';

import * as ROUTES from './constants/routes';
import { withAuthentication } from './modules/Session';

const App = () => (
    <Router>
    <div className="App">
        <header className="App-header">
            <img src={logo} className="App-logo" alt="Scoreside Logo" />
        </header>

        <Navigation />

        <hr/>

        <div className="main-content">
            <Route exact path={ROUTES.HOME} component={HomePage} />
            <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
            <Route path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
            <Route path={ROUTES.ACCOUNT} component={AccountPage} />
            <Route path={ROUTES.ADMIN} component={AdminPage} />
            <Route path={ROUTES.OAUTH} component={OAuthPage} />
            <Route path={ROUTES.ORG_REQUEST} component={OrgRequestPage} />
            <Route path={ROUTES.ADMIN_REQUEST} component={AdminRequestPage} />
        </div>
    </div>
    </Router>
);

export default withAuthentication(App);
