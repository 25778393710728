export const HOME = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const OAUTH = '/oauth';
export const OAUTH_CLIENT = '/oauth/:client';
export const ORG_REQUEST = '/org-request';
export const ADMIN_REQUEST = '/admin-request/:orgRequestId';
