const firebaseConfig = {
	get prod() {
		return {
			projectId: 'scoreside-64b16',
			apiKey: 'AIzaSyCvtsmWhYr9fBgZ_NNG2rRkGKMnmX7UwfA',
			username: 'alex+causebot@causelinelabs.io',
			password: 'alex+causebot',
			baseApiUrl: `https://us-central1-scoreside-64b16.cloudfunctions.net/api`,
			useLocalFunctions: false,
			localFunctionsApiUrl: 'https://scoreside-local.ngrok.io/scoreside-64b16/us-central1/api'
		};
	},

	get staging() {
		return {};
	},

	get dev() {
		return {
			projectId: 'goodgame-29001',
			apiKey: 'AIzaSyCcEjp9E6BPGZleBU2PakZDj9aI0lLvKGc',
			username: 'alex+causebot@causelinelabs.io',
			password: 'alex+causebot',
			baseApiUrl: `https://us-central1-goodgame-29001.cloudfunctions.net/api`,
			useLocalFunctions: false,
			localFunctionsApiUrl: 'https://scoreside-local.ngrok.io/scoreside-64b16/us-central1/api'
		};
	},

	get local() {
		return {
			projectId: 'goodgame-29001',
			apiKey: 'AIzaSyCcEjp9E6BPGZleBU2PakZDj9aI0lLvKGc',
			username: 'alex+causebot@causelinelabs.io',
			password: 'alex+causebot',
			baseApiUrl: `https://us-central1-goodgame-29001.cloudfunctions.net/api`,
			useLocalFunctions: false,
			localFunctionsApiUrl: 'https://scoreside-local.ngrok.io/scoreside-64b16/us-central1/api'
		};
	},

	get local_prod() {
		return {
			projectId: 'goodgame-29001',
			apiKey: 'AIzaSyCvtsmWhYr9fBgZ_NNG2rRkGKMnmX7UwfA',
			username: 'alex+causebot@causelinelabs.io',
			password: 'alex+causebot',
			baseApiUrl: `https://us-central1-goodgame-29001.cloudfunctions.net/api`,
			useLocalFunctions: false,
			localFunctionsApiUrl: 'https://scoreside-local.ngrok.io/scoreside-64b16/us-central1/api'
		};
	},

	get test() {
		return {
			projectId: 'test',
			apiKey: 'test',
			username: 'test',
			password: 'test',
			baseApiUrl: `test`,
			useLocalFunctions: false,
			localFunctionsApiUrl: 'test'
		};
	},

	get config() {
		return {
			firestoreSettings: {
				timestampsInSnapshots: true
			},

			messagingSenderId: '987225075281',

			authDomain: `${this[process.env.REACT_APP_ENV].projectId}.firebaseapp.com`,

			databaseURL: `https://${this[process.env.REACT_APP_ENV].projectId}.firebaseio.com`,

			storageBucket: `${this[process.env.REACT_APP_ENV].projectId}.appspot.com`,

			...this[process.env.REACT_APP_ENV]
		};
	}
};

export default firebaseConfig.config;
