import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

import firebaseConfig from '../../config/firebaseConfig';

const fbConfig = {
    apiKey: firebaseConfig.apiKey,
    authDomain: firebaseConfig.authDomain,
    databaseURL: firebaseConfig.databaseURL,
    projectId: firebaseConfig.projectId,
    storageBucket: firebaseConfig.storageBucket,
    messagingSenderId: firebaseConfig.messagingSenderId
};

class FirebaseService {
    constructor() {
        firebase.initializeApp(fbConfig);

        this.auth = firebase.auth();
        this.firestore = firebase.firestore();
        this.functions = firebase.functions();

        // Enable locally hosted function development when ENV is set to `local`
        if (firebaseConfig.useLocalFunctions) {
            this.functions.useFunctionsEmulator('http://localhost:5000');
        }
    }

    // *** Functions API ***
    authorizeStripe = async(data) => {
        const authorizeStripeForOrg = this.functions.httpsCallable('authorizeStripeForOrg');
        return authorizeStripeForOrg(data);
    }

    sendOrgRequestEmail = async(data) => {
        const sendOrgRequestEmail = this.functions.httpsCallable('sendOrgRequestEmail');
        return sendOrgRequestEmail(data);
    }

    // *** Auth API ***
    doCreateUserWithEmailAndPassword = (email, password) => {
        return this.auth.createUserWithEmailAndPassword(email, password);
    }
    

    doSignInWithEmailAndPassword = (email, password) => {
        return this.auth.signInWithEmailAndPassword(email, password);
    }

    doSignOut = () => {
        return this.auth.signOut();
    }

    doPasswordReset = email => {
        return this.auth.sendPasswordResetEmail(email);
    }

    doPasswordUpdate = password => {
        return this.auth.currentUser.updatePassword(password);
    }

    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                this.getUserById(authUser.uid)
                    .then(firestoreUser => {
                        // Return firestore user as authUser so we have access to all the DB fields
                        next(firestoreUser);
                    });
            } else {
                fallback();
            }
        });

    // *** User API ***
    createUser(authUser, username, email) {
        return this.firestore.collection('users').doc(authUser.user.uid).set({
            uid: authUser.user.uid,
            username: username,
            displayName: username,
            email: email
        });
    }

    getUser(id) {
        const lowercaseId = id.toLowerCase();
        return lowercaseId.startsWith('users/')
            ? this.getUserById(id.substring(id.indexOf('/') + 1))
            : lowercaseId.startsWith('id:') || lowercaseId.startsWith('uid:')
            ? this.getUserById(id.substring(id.indexOf(':') + 1))
            : lowercaseId.startsWith('email:')
            ? this.getUserByEmail(id.substring(id.indexOf(':') + 1))
            : lowercaseId.startsWith('displayname:')
            ? this.getUserByDisplayName(id.substring(id.indexOf(':') + 1))
            : lowercaseId.includes('@')
            ? this.getUserByEmail(id)
            : this.getUserByDisplayName(id);
    }

    getUserById = async function(userId) {
        var rejectionReason;
        const res = await this.getDocument('users', userId).catch(err => {
            console.error(`Error finding user by UID:\n`, err);
            rejectionReason = err;
        });
        if (rejectionReason) return Promise.reject(rejectionReason);
        if (!res) return Promise.reject(`No user found for UID ${userId}`);

        return res.data();
    }

    getUserByDisplayName = async function(displayName) {
        var rejectionReason;
        const res = await this.runQuery('users', [
            'displayName',
            '==',
            displayName
        ]).catch(err => {
            console.error(`Error finding user by displayName:\n`, err);
            rejectionReason = err;
        });
        if (rejectionReason) return Promise.reject(rejectionReason);
        if (!res)
            return Promise.reject(
                `No user found for displayName ${displayName}`
            );

        if (res.size === 1) {
            return res.docs.pop().data();
        } else {
            // Multiple users found with same displayName
            return Promise.reject(res.docs);
        }
    }

    getUserByEmail = async function(email, refOnly) {
        var rejectionReason;
        const res = await this.runQuery('users', ['email', '==', email]).catch(
            err => {
                console.error(`Error finding user by email:\n`, err);
                rejectionReason = err;
            }
        );
        if (rejectionReason) return Promise.reject(rejectionReason);
        if (!res) return Promise.reject(`No user found for email ${email}`);

        if (res.size === 1) {
            // If refOnly is passed, return the ref for this user instead of the data
            return refOnly ? res.docs.pop() : res.docs.pop().data();
        } else {
            // Multiple users found with same email
            return Promise.reject(res.docs);
        }
    }

    getDocument = async function(collection, name) {
        return new Promise(async (resolve, reject) => {
            this.firestore
                .collection(collection)
                .doc(name)
                .get()
                .then(data => {
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getDocumentFromSubcollection = async function(collection, docId, subcollection, id) {
        return new Promise(async (resolve, reject) => {
            this.firestore
                .collection(collection)
                .doc(docId)
                .collection(subcollection)
                .doc(id)
                .get()
                .then(data => {
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getReference(collection, name) {
        return this.firestore
            .collection(collection)
            .doc(name);
    }

    getSubcollection(collection, docId, subcollection) {
        return new Promise((resolve, reject) => {
            this.firestore
                .collection(collection)
                .doc(docId)
                .collection(subcollection)
                .get()
                .then(data => {
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    runQuery = async function(collectionName, ...queryArrays) {
        return new Promise(async (resolve, reject) => {
            var query = this.firestore.collection(collectionName);
            for (let queryArray of queryArrays) {
                query = query.where(...queryArray);
            }
            query
                .get()
                .then(snapshot => {
                    resolve(snapshot.empty ? undefined : snapshot);
                })
                .catch(err => {
                    console.error(
                        `Error getting documents from '${collectionName}' collection:\n`,
                        err
                    );
                    reject(err);
                });
        });
    }

    update = async function(object, values) {
        return new Promise(async (resolve, reject) => {
            if (!object.update) {
                if (object.ref && object.ref.update) {
                    object = object.ref;
                } else {
                    return reject(
                        'Object has no update method nor a `ref` param with an update method'
                    );
                }
            }
            object
                .update(values)
                .then(() => {
                    return resolve();
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    users = async function() {
        return new Promise(async (resolve, reject) => {
            this.firestore
                .collection('users')
                .get()
                .then(data => {
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };
}

export default FirebaseService;
