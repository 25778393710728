import React from 'react';
import { compose } from 'recompose';

import { AuthUserContext, withAuthorization } from '../../modules/Session';
import { withFirebase } from '../../modules/Firebase';
import OrgRequestForm from './OrgRequestForm';
import OrgRequestList from './OrgRequestList';

const OrgRequestPage = () => (
    <AuthUserContext.Consumer>
        { authUser => (
            <div>
                <h1>Get Your Charitable Organzation on ScoreSide!</h1>

                <OrgRequestList userId={authUser.uid} filter="pending" />

                <OrgRequestForm requesterDisplayName={authUser.displayName} requesterId={authUser.uid} requesterEmail={authUser.email} />

                <OrgRequestList userId={authUser.uid} filter="!pending" />
            </div>
        )}
    </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition),
    withFirebase
)(OrgRequestPage)
