import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { withFirebase } from '../../modules/Firebase';
import loadingIcon from '../../images/loading.gif';
import causebotConfig from '../../config/causebotConfig';

const AdminRequestForm = ({ userId, userDisplayName, userEmail, orgRequestId, firebaseService }) => {
    const [ orgRequest, setOrgRequest ] = useState(undefined);
    const [ loading, setLoading ] = useState(false);
    const [ submitted, setSubmitted ] = useState(false);
    const [ error, setError ] = useState(undefined);

    useEffect(() => {
        firebaseService.getDocumentFromSubcollection(
            'users',
            userId,
            'orgRequests',
            orgRequestId
        )
        .then(orgRequestDoc => {
            const orgRequest = orgRequestDoc.data();

            if (orgRequest) {
                setOrgRequest(orgRequest);

            } else {
                setOrgRequest(null);
            }
        })
        .catch(err => console.error('ERROR **', err));
    });

    const onSubmit = async e => {
        e.preventDefault();

        setLoading(true);

        const res = await fetch(
            causebotConfig.adminRequestUrl,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-admin-token': causebotConfig.accessToken
                },
                body: JSON.stringify({
                    userId,
                    userDisplayName,
                    userEmail,
                    orgRequestId
                })
            })
            .catch(err => console.error('ERROR:', err));

        if (!res) { return; }
        if (!res.ok) {
            console.error('Failed request:', res.statusCode);
            // TODO: Handle error

            return;
        }

        setLoading(false);
        setSubmitted(true);
    }

    return (
        <>{ orgRequest ? 
            (
                <div style={{ textAlign: 'center' }}>
                    { (submitted || (orgRequest.adminRequest && orgRequest.adminRequest.accepted) == null) ?
                        (
                            <p>We've received your request for administrative privileges for this organization on ScoreSide. We'll update you via email once we've accepted your request, or to follow up for more information if needed.</p>

                        ) : (orgRequest.adminRequest && orgRequest.adminRequest.accepted) ? (
                            <p>Your admin request has been accepted! Check the Admin tab to administer this org.</p>

                        ) : (orgRequest.adminRequest && orgRequest.adminRequest.accepted === false) ? (
                            <p>Your admin request was rejected.</p>

                        ) : (orgRequest.accepted && !orgRequest.adminRequest) ? (
                            <p>Hit the button below to request administrative privileges on this org.</p>

                        ) : orgRequest.accepted === false ? (
                            <p>Sorry, you can't request administrative privileges on rejected orgs.</p>

                        ) : (
                            <p>Sorry, you can't request administrative privileges until your org has been accepted to the ScoreSide platform. We'll update you via email once we've accepted your org, or to follow up for more information if needed.</p>
                        )
                    }
                    <div className='org-request' style={{ textAlign: 'left' }}>
                        <div>
                            Name: {orgRequest.orgName}
                        </div>
                        <div>
                            Org Status: {orgRequest.accepted != null
                                ? (orgRequest.accepted ? 'Accepted' : 'Rejected')
                                : 'Pending'}
                        </div>
                        <div>
                            Submitted: {format(orgRequest.dateCreated.toDate(), 'MMMM d, yyyy')}
                        </div>
                        { orgRequest.adminRequest &&
                            <div>
                                Admin Status: {orgRequest.adminRequest.accepted != null
                                    ? (orgRequest.adminRequest.accepted ? 'You are an admin for this org' : 'Your admin request was rejected')
                                    : 'Pending'}
                            </div>
                        }
                        { (!(submitted || orgRequest.adminRequest) && orgRequest.accepted) &&
                            <form>
                                <button className="loading-state" disabled={loading} onClick={onSubmit}>
                                    <span className={loading ? "loading-icon" : "loading-icon hidden"}><img src={loadingIcon} alt="loading..." /></span><span className={loading ? "label hidden" : "label"}>Request Admin</span>
                                </button>
                            </form>
                        }

                        {error && <p>{error.message}</p>}
                    </div>
                </div>
            ) : orgRequest === null ? (
                <div style={{ textAlign: 'center' }}>
                    No org requests found for the given ID. Check your Org Requests page and make sure you're logged in under the correct account.
                </div>
            ) : (
                <div className='page-load'>
                    <label>One moment please...</label>
                    <span className={'loading-icon'}>
                        <img src={loadingIcon} alt="loading..." style={{ height: '40px' }} />
                    </span>
                </div>
            )
        }</>
    );
}

export default withFirebase(AdminRequestForm);
