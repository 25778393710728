const stripeConfig = {
    get prod() {
        return {
            apiKey: 'sk_live_4QZr2T6wA7lyimSbD85hykpl00cN1WdeLe',
            clientId: 'ca_FEgMdSwaYhijMsoaCeb6jUtpHnTRrdK6'
        };
    },

    get staging() {
        return {};
    },

    get dev() {
        return {
            apiKey: 'rk_test_OkksgPDatfofJYYodcYcDYwI003jKyOStA',
            clientId: 'ca_FEgMZpcXnY3mYna5ihcvcZNLro7b1dae'
        };
    },

    get local() {
        return {
            apiKey: 'rk_test_OkksgPDatfofJYYodcYcDYwI003jKyOStA',
            clientId: 'ca_FEgMZpcXnY3mYna5ihcvcZNLro7b1dae'
        };
    },

    get local_prod() {
        return {
            apiKey: 'rk_test_OkksgPDatfofJYYodcYcDYwI003jKyOStA',
            clientId: 'ca_FEgMZpcXnY3mYna5ihcvcZNLro7b1dae'
        };
    },

    get test() {
        return {
            apiKey: 'test',
            clientId: 'test'
        };
    },

    get config() {
        return {
            oauthUrl: 'https://connect.stripe.com/express/oauth/authorize',

            ...this[process.env.REACT_APP_ENV]
        };
    }
};

export default stripeConfig.config;
