const roleService = {
	isCharityAdmin(authUser) {
		return authUser
		       && authUser.admin
		       && authUser.admin.organizations
		       && authUser.admin.organizations.length > 0;
	},

	isSuperuser(authUser) {
		return authUser
		       && authUser.admin
		       && authUser.admin.superuser === true;
	}
};

export default roleService;
