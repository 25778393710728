import React from 'react';

const FirebaseContext = React.createContext(null);

export const withFirebase = Component => props => (
	<FirebaseContext.Consumer>
		{ firebaseService => <Component {...props} firebaseService={firebaseService} /> }
	</FirebaseContext.Consumer>
);

export default FirebaseContext;
