import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const HomePage = () => (
    <div>
        <h1>Welcome to Scoreside!</h1>
        <p>We're an innovative charitable giving platform empowering fans to donate to good causes through the lens of the sports they love.</p>
        <p>For more information on our platform and mission, see our <a href="https://scoreside.io">main website</a>.</p>
        <p>If you've come to this site, you're likely a charity administrator looking to request that your charity be added to our platform. In just a few easy steps, you can submit a request and then get your charity set up once the request is apporved.</p>

        <p>Just follow <Link to={ROUTES.ORG_REQUEST}>this link</Link> to submit your request!</p>
    </div>
);

export default HomePage;
