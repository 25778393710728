import React from 'react';
import ReactDOM from 'react-dom';
import './style.css';
import * as serviceWorker from './serviceWorker';

import App from './App';
import FirebaseService, { FirebaseContext } from './modules/Firebase';

ReactDOM.render(
    <FirebaseContext.Provider value={new FirebaseService()}>
        <App />
    </FirebaseContext.Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
