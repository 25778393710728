import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import queryString from 'query-string';

import { AuthUserContext, roleService, withAuthorization } from '../../modules/Session';
import { withFirebase } from '../../modules/Firebase';

import * as ROUTES from '../../constants/routes';

const OAuthPage = () => (
    <Switch>
        <Route exact path={ROUTES.OAUTH_CLIENT} component={OAuthClient} />
        <Redirect exact from={ROUTES.OAUTH} to={ROUTES.HOME} />
    </Switch>
);

class OAuthClient extends Component {
    render() {
        const queryParams = queryString.parse(this.props.location.search);

        return (
            this.props.match.params.client === 'stripe' ? (
                <AuthUserContext.Consumer>
                    { authUser =>
                        <StripeOAuthClient queryParams={queryParams} authUser={authUser} />
                    }
                </AuthUserContext.Consumer>
            ) : (
                <Redirect to={ROUTES.HOME} />
            )
        );
    }
};

class StripeOAuthClientBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            authenticating: false,
            code: this.props.queryParams.code,
            orgId: this.props.queryParams.state,
            userId: this.props.authUser.uid,
            success: null
        }
    }

    componentDidMount = async() => {
        this.setState({ authenticating: true });

        if (!this.stripeAuthDidFail()) {
            const data = {
                code: this.state.code,
                orgId: this.state.orgId,
                userId: this.state.userId
            };

            try {
                await this.props.firebaseService.authorizeStripe(data);
                this.setState({ authenticating: false, success: true });

            } catch (err) {
                console.error('Stripe OAuth authorization failure:', err);
                this.setState({ authenticating: false, success: false });
            }
        }
    }

    stripeAuthDidFail() {
        return this.state.code == null
               || this.state.orgId == null
               || this.state.userId == null
               || this.state.success === false;
    }

    render() {
        const { authenticating, success } = this.state;

        return ((this.stripeAuthDidFail()) ? <StripeOAuthError /> : (
            <div>
                { authenticating && <div>Authenticating to Stripe...</div> }

                { success &&
                    <div>
                        Successfully authenticated to Stripe!
                    </div>
                }
            </div>
        ));
    }
}

const StripeOAuthError = () => (
    <div>
        Failed to authenticate with Stripe, please retry auth flow from the Admin page.
    </div>
);

const condition = authUser => roleService.isCharityAdmin(authUser);

const StripeOAuthClient = withFirebase(StripeOAuthClientBase);

export default compose(
    withAuthorization(condition),
    withFirebase
)(OAuthPage)
