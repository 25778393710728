import React, { Component } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../../modules/Firebase';

const INITIAL_STATE = {
    email: '',
    organizationId: '',
    error: null,
    changed: false
};

class CreateAdminFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email, organizationId } = this.state;
        
        this.props.firebaseService
            .getUserByEmail(email, true) // Get the user ref only
            .then((user) => {
                const userData = user.data();
                const orgAdd = { admin: userData.admin ? userData.admin : {} };
                const orgRef = this.props.firebaseService
                    .getReference('organizations', organizationId);

                if (!orgAdd.admin.organizations) {
                    orgAdd.admin.organizations = [];
                } else {
                    if (orgAdd.admin.organizations.find(existingOrg => existingOrg.id === orgRef.id)) {
                        throw new Error('User is already an admin of this organization');
                    }
                }

                orgAdd.admin.organizations.push(orgRef);

                this.props.firebaseService
                    .update(user, orgAdd)
                    .then(() => {
                        this.setState({ ...INITIAL_STATE, changed: true });
                    })
                    .catch((error) => {
                        this.setState({ error });
                    });
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, organizationId, error, changed } = this.state;

        const isInvalid = email === '' || organizationId === '';

        return (
            <form onSubmit={this.onSubmit}>
                <input
                    name="email"
                    value={email}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Email Address"
                />
                <input
                    name="organizationId"
                    value={organizationId}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Organization ID"
                />
                <button disabled={isInvalid} type="submit">
                    Set Admin
                </button>

                {error && <p>{error.message}</p>}

                {changed && <p>Admin privileges added successfully</p>}
            </form>
        );
    }
}
const CreateAdminForm = compose(
    withFirebase
)(CreateAdminFormBase);

export default CreateAdminForm;
