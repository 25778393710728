import React, { Component } from 'react';

import { withFirebase } from '../../modules/Firebase';

const INITIAL_STATE = {
    passwordOne: '',
    passwordTwo: '',
    error: null,
    changed: false
};

class PasswordChangeForm extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { passwordOne } = this.state;
        
        this.props.firebaseService
            .doPasswordUpdate(passwordOne)
            .then(() => {
                this.setState({
                    passwordOne: '',
                    passwordTwo: '',
                    error: null,
                    changed: true
                });
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { passwordOne, passwordTwo, error, changed } = this.state;
        
        const isInvalid = passwordOne !== passwordTwo || passwordOne === '';

        return (
            <form onSubmit={this.onSubmit}>
                <input
                    name="passwordOne"
                    value={passwordOne}
                    onChange={this.onChange}
                    type="password"
                    placeholder="New Password"
                />
                <input
                    name="passwordTwo"
                    value={passwordTwo}
                    onChange={this.onChange}
                    type="password"
                    placeholder="Confirm New Password"
                />
                <button disabled={isInvalid} type="submit">
                    Change Password
                </button>
                
                {error && <p>{error.message}</p>}

                {changed && <span>Your password was changed successfully</span>}
            </form>
        );
    }
}

export default withFirebase(PasswordChangeForm);
