import React from 'react';
import { compose } from 'recompose';

import { AuthUserContext, withAuthorization } from '../../modules/Session';
import AdminRequestForm from './AdminRequestForm';

const AdminRequestPage = (props) => {
    const { orgRequestId } = props.match.params;

    return (
        <AuthUserContext.Consumer>
            { authUser => (
                <AdminRequestForm userId={authUser.uid} userDisplayName={authUser.displayName} userEmail={authUser.email} orgRequestId={orgRequestId} />
            )}
        </AuthUserContext.Consumer>
    )
};

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition)
)(AdminRequestPage);
